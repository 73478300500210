.projectsListContainer {
  background: #ffffff;
  flex-direction: row;
  min-height: 90%;
  min-width: 90%;
  margin-top: 50px;
  margin-bottom: 50px;
}
.projectsList {
  padding-left: 10px;
  padding-right: 10px;
  overflow-y: scroll;
  height: 86.5%;
}
.formContainer {
  padding: 25px;
  background-color: white;
  width: 90%;
}
.projectContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.projectHr {
  border: 1px solid #e5e5e5;
}
.projectDetailContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0px;
}
.projectImageContainer {
  display: flex;
  flex-direction: column;
}
.projectPriv {
  background-color: #e30b5c;
  border-radius: 5px;
  padding: 10px;
  padding-left: 20px;
  padding-right: 20px;
  border: 1px solid #e30b5c;
  height: fit-content;
  margin-right: 10px;
  color: white;
  /* text-transform: uppercase; */
  display: flex;
  flex-direction: row;
  font-size: 16px;
  cursor: pointer;
}
.projectPrivContainer {
  display: flex;
  flex-direction: row;
}
@media only screen and (max-width: 768px) {
  .projectPrivContainer {
    flex-direction: column;
  }
  .projectPriv {
    margin-bottom: 5px;
  }
}
