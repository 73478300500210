.outerFormContainer {
  align-items: center;
  justify-content: center;
  display: flex;
  flex: 1;
  height: 100%;
  width: 100%;
  background-color: #e30b5c;
  margin: 0;
}
.outerFormContainerSignIn {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  height: 100vh;
  width: 100%;
  background-color: #e30b5c;
  margin: 0;
}
.separator {
  display: flex;
  align-items: center;
  text-align: center;
}
.signinFormContainer {
  padding: 25px;
  background-color: white;
  width: 30%;
}

.separator::before,
.separator::after {
  content: "";
  flex: 1;
  border-bottom: 1px solid #000;
}

.separator:not(:empty)::before {
  margin-right: 0.25em;
}

.separator:not(:empty)::after {
  margin-left: 0.25em;
}
.bottomSigninText {
  text-align: center;
}
.descriptionSelectionInput {
  width: 98%;
  padding: 15px;
  margin: 5px 0 22px 0;
  display: inline-block;
  border: none;
  background: #f1f1f1;
}

.descriptionSelectionInput:focus {
  background-color: #ddd;
  outline: none;
}
