.outerContainer {
  display: flex;
  flex: 1;
  flex-direction: column;
  background-color: #e30b5c;
  align-items: center;
  justify-content: center;
}
.formContainer {
  padding: 25px;
  background-color: white;
  width: 60%;
}
.mainContainer {
  display: flex;
  flex: 1;
  height: 100vh;
  width: 100vw;
  background-color: #e30b5c;
  margin: 0;
}

.headerText {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  color: whitesmoke;
  font-weight: 900;
}
.descriptionInput {
  width: 95%;
  padding: 15px;
  margin: 5px 0 22px 0;
  display: inline-block;
  border: none;
  background: #f1f1f1;
}

.descriptionInput:focus {
  background-color: #ddd;
  outline: none;
}

/* Overwrite default styles of hr */
.NewProjectHr {
  border: 1px solid #f1f1f1;
  margin-bottom: 25px;
}

/* Set a style for the submit button */
.submitButton {
  background-color: #f33a6a;
  color: white;
  padding: 16px 20px;
  margin: 8px 0;
  border: none;
  cursor: pointer;
  width: 100%;
  font-weight: 900;
  font-size: 18px;
}

.submitButton:hover {
  opacity: 0.9;
}
@media only screen and (max-width: 768px) {
  .mainContainer {
    flex-direction: column;
  }
}
