.headerContainer{
    display: flex;
    flex-direction: row;
    color:black;
    font-weight: 900;
    font-size:20px;
    width:100%;
    flex:1;
    position:fixed;
    top:0;
    background-color: white;
    box-shadow: 2px 2px 5px rgba(0,0,0,.1); 
    width: 100%;
    overflow: hidden;
    padding-block: 20px;
    padding-left: 20px;
    flex-direction: row;
}
.headerText{
    margin-right:30px;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    padding:0;
    align-items: center;
    justify-content: space-around;
    
}