.outerNodeFormContainer {
  padding: 20px;
  width: 500px;
}
.deleteButton {
  display: flex;
  flex-direction: row-reverse;
}
.descriptionInput {
  width: 95%;
  padding: 15px;
  margin: 5px 0 22px 0;
  display: inline-block;
  border: none;
  background: #f1f1f1;
}
.colorInputLabel {
  margin-right: 20px;
}
.tabSection {
  margin-bottom: 10px;
  color: #f33a6a;
}
.tabValues {
  color: #f33a6a;
  font-weight: 600;
}
/* .selectionInput {
  margin-top: 10px;
  width: 75%;
  margin-left: 40px;
  background: #f1f1f1;
  height: 50px;
  border: none;
} */
.colorInputDiv {
  margin-top: 30px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: space-between;
}
.colorInputDiv input {
  -webkit-appearance: none;
  width: 30px;
  height: 30px;
  border: 0;
  border-radius: 50%;
  border: solid;
  padding: 0;
  overflow: hidden;
  margin-right: 100px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
}
.colorInputDiv input::-webkit-color-swatch-wrapper {
  padding: 0;
}
.colorInputDiv input::-webkit-color-swatch {
  border: none;
}
.timerInput {
  padding: 10px;
  display: inline-block;
  border: none;
  background: #f1f1f1;
}
.timerDiv {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.nodePicInput {
  margin-bottom: 30px;
}
.nodePicInput input {
  background-color: #f33a6a;
  color: white;
  border-radius: 40px;
  margin-right: 10px;
}
.nodePicInput label {
  margin-right: 10px;
}
.nodePicInput input[type="file"] {
  padding: 0 gap(m);
  width: 100px;
  font-weight: 900;
}
.nodePicInput input[type="file"]::-webkit-file-upload-button {
  visibility: hidden;
  margin-left: 10px;
  padding: 0;
  padding-top: 10px;
  padding-bottom: 10px;
  width: 0;
}
.descriptionInput:focus {
  background-color: #ddd;
  outline: none;
}
.NewProjectHr {
  border: 1px solid #f1f1f1;
  margin-bottom: 25px;
}

/* Set a style for the submit button */
.submitButton {
  background-color: #f33a6a;
  font-weight: 900;
  color: white;
  padding-top: 10px;
  padding-bottom: 10px;
  margin: 8px 0;
  border-radius: 40px;
  border: none;
  cursor: pointer;
  width: 100px;
}

.submitButton:hover {
  opacity: 1;
}
.fileUpload {
  height: 0;
  width: 0;
  overflow: hidden;
}
.uploadBox input[type="file"]::-webkit-file-upload-button {
  cursor: pointer;
}
.matchingOuterContainer {
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.12);
  display: flex;
}
.innerColumn {
  width: 49.5%;
  display: flex;
  flex-direction: column;
}
.columnInput {
  margin-top: 10px;
  border: none;
  outline: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  margin-bottom: 5px;
  width: 100%;
}
.DnDColumnInput {
  border: none;
  outline: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  width: 100%;
}
