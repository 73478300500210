.chooseImageContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
#img_preview {
  object-fit: cover;
  height: 7rem;
  width: 7rem;
  border: 2px dashed #333;
  margin: 10px 0px;
}
#img_preview img {
  width: 100%;
  height: 100%;
  display: block;
}
[type="file"] {
  height: 0;
  width: 0;
  overflow: hidden;
}
[type="file"] + label {
  width: 100%;
  text-align: center;
  background: #f33a6a;
  border: 1px solid #f33a6a;
  padding: 3px;
  color: #fff;
  cursor: pointer;
  transition: all 0.2s;
}
[type="file"] + label:hover {
  background-color: #fff;
  color: #f44336;
}
