.webcamOuterContainer {
  width: 100%;
  position: relative;
}
.webcamInnerContainer {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  flex-direction: column;
}
.webcamInnestContainer {
  height: 220px;
  width: 220px;
  position: relative;
}
.infoBar {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 30px;
  background: rgba(14, 67, 58, 0.5);
  backdrop-filter: blur(35px);
  display: flex;
  align-items: center;
  padding: 0 10px;
  justify-content: space-between;
}
.info {
  color: #fff;
  font-family: Fuzzy Bubbles;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 133.333% */
  margin-right: 5px;
}
.scoreContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}
.webcamVideo {
  object-fit: cover;
  background-color: #0e433a;
  height: 220px;
  width: 220px;
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}
.isCameraOn {
  position: relative;
  height: 220px;
  width: 220px;
  background-color: #0e433a;
  display: flex;
  justify-content: center;
  align-items: center;
}
.bodyIcon {
  height: 120px;
  width: 120px;
}
.questionContainer {
  position: absolute;
  top: 30%;
  left: 50%;
  font-size: 20px;
  width: 80%;
  line-height: 1.5;
  border-radius: 10px;
  border: solid black;
  background-color: white;
  opacity: 1;
  transform: translateX(-50%) translateY(-50%);
  -ms-transform: translateX(-50%) translateY(-50%);
}
.questionText {
  padding: 20px;
  text-align: center;
}
.questionChoicesContainer {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 60%;
  height: 60%;
  z-index: -100;
  transform: translateX(-50%) translateY(-50%);
  background: no-repeat;
  background-size: cover;
  opacity: 0.9;
  background-color: white;
  border-radius: 40px;
}
.choicesContainer {
  position: absolute;
  top: 80%;
  left: 50%;
  font-size: 20px;
  width: 80%;
  line-height: 1.5;
  opacity: 1;
  transform: translateX(-50%) translateY(-50%);
  -ms-transform: translateX(-50%) translateY(-50%);
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}
.choiceButton {
  background-color: #e30b5c;
  color: white;
  padding: 0px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  transition-duration: 0.4s;
  cursor: pointer;
  border-radius: 10px;
}
.choiceButton:hover {
  background-color: white;
  color: black;
  border: 2px solid #e30b5c;
}
.questionBoxContainer {
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(7, 33, 29, 0.5);
}
.questionBoxInnerContainer {
  padding: 35px;
  min-width: 700px;
  max-width: 900px;
  min-height: 360px;
  flex-shrink: 0;
  border-radius: 20px;
  background: var(--secondary-50, #edf1fc);
}
.question {
  color: var(--secondary-800, #1e2c5a);
  font-family: Fuzzy Bubbles;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 32px;
  text-align: justify;
  text-justify: inter-word;
}
.optionAndTimerContainer {
  display: flex;
}
.leftQuestionBoxContainer {
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding-right: 20px;
}
.rightQuestionBoxContainer {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.option {
  text-align: justify;
  text-justify: inter-word;
  cursor: pointer;
  display: flex;
  padding: 12px 24px;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  background: var(--secondary-500, #4a6ee0);
  box-shadow: 6px 6px 0px 0px #ffdf80,
    0px 4px 8px 0px rgba(237, 241, 252, 0.56) inset;
  color: var(--secondary-50, #edf1fc);
  font-family: Fuzzy Bubbles;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 150% */
  letter-spacing: -0.04px;
}
.timerContainer {
  width: 241px;
  height: 241px;
  flex-shrink: 0;
  border-radius: 500px;
  background: var(--accent-3100, #fff5d9);
  box-shadow: -4px 4px 0px 0px #99864d;
  display: flex;
  justify-content: center;
  align-items: center;
}
.timeContainer {
  display: flex;
  justify-content: center;
  align-items: baseline;
  margin-top: 10px;
}
.time {
  color: var(--accent-3700, #99864d);
  text-align: right;
  font-family: Fuzzy Bubbles;
  font-size: 56px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.14px;
}
.sec {
  color: var(--accent-3700, #99864d);
  font-family: Fuzzy Bubbles;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.06px;
}
#outerContainer {
  position: relative;
}
.outerControlsContainer {
  position: absolute;
  bottom: 0;
  display: flex;
  justify-content: center;
  overflow: hidden;
  width: 100%;
  height: 70px;
}
.outerControlsContainer:hover #controlsContainer {
  transition: 0.5s;
  bottom: 0;
}
#controlsContainer {
  position: absolute;
  bottom: -59px;
  transition: 1s;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: rgba(14, 67, 58, 0.32);
  width: 640px;
  backdrop-filter: blur(8px);
  border-radius: 12px 12px 0px 0px;
}
.classTitle {
  font-family: "Poppins";
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.0025em;
  color: #e9f6f4;
  margin: 10px;
}
.iconsContainer {
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}
.outerCirce {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid white;
  border-radius: 50%;
  padding: 2px;
  margin: 10px;
  cursor: pointer;
}
.endButton {
  width: 130px;
  height: 50px;
  background: #ef4444;
  border-radius: 25px;
  border: none;
  color: white;
  font-family: "Poppins";
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  margin: 10px;
  cursor: pointer;
}
.endClassIcon {
  margin-right: 5px;
  font-size: 20px;
}
.btnInnerContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
