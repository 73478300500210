.header {
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff9f4;
}
.clapingoLogo {
  height: 40px;
  width: 134px;
}
.container {
  height: calc(100vh - 80px);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.headingContainer {
  margin-bottom: 20px;
}
.heading1 {
  color: #0e433a;
  font-family: "Poppins";
  font-weight: 600;
  font-size: 36px;
  line-height: 52px;
}
.heading2 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
  color: #647b76;
}
.bodyIcon {
  height: 120px;
  width: 120px;
  margin-bottom: 10px;
}
.videoContainer {
  position: relative;
  width: 640px;
  height: 480px;
  background: #0e433a;
}
.video {
  width: 640px;
  height: 480px;
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}
.dummyVideoScreen {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 640px;
  height: 480px;
  flex-direction: column;
  background-color: #0e433a;
}
.heading3 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #e9f6f4;
}
.iconsContainer {
  color: white;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  margin-bottom: 10px;
  align-items: center;
  justify-content: center;
}
.outerCirce {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid white;
  border-radius: 50%;
  padding: 5px;
  margin: 10px;
  cursor: pointer;
}
.startButton {
  width: 150px;
  height: 56px;
  background: #24a791;
  border-radius: 12px;
  border: none;
  color: white;
  font-family: "Poppins";
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  margin: 10px;
}
/* .MicOffIcon {
  height: 100px;
  width: 100px;
}
.MicOnIcon {
  height: 100px;
  width: 100px;
}
.CameraOffIcon {
  height: 100px;
  width: 100px;
}
.CameraOnIcon {
  height: 100px;
  width: 100px;
} */
