.ootagsInput {
  margin-top: 15px;
  display: flex;
  align-items: center;
  padding: 0px 10px;
  gap: 10px;
  border: 1px solid rgb(214, 216, 218);
  border-radius: 6px;

  input {
    height: 32px;
    margin: 13px 0px;
    border: none;
    font-size: 15px;
    background-color: #fff;

    &:focus {
      outline: none;
    }
  }
}

#ootags {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
}

.ootag {
  height: 32px;
  display: flex;
  align-items: center;
  margin-left: 0.2vw;
  justify-content: center;
  color: #fff;
  padding: 0 8px;
  font-size: 14px;
  list-style: none;
  border-radius: 6px;
  background: #e30b5c;

  .ootagCloseIcon {
    font-size: 20px;
    margin-left: 8px;
    cursor: pointer;
  }
}

// @media screen and (max-width: 567px) {
// 	.tags-input {
// 		width: calc(100vw - 32px);
// 	}
// }

// .ootagsInput input::placeholder {
//   height: 32px;
//   font-family: "Inter";
//   font-style: normal;
//   font-weight: 700;
//   font-size: 16px;
//   line-height: 19px;
//   display: flex;
//   align-items: center;
//   color: #223354;
//   opacity: 0.5;
// }

// @media screen and (max-width: 790px) {
//   .ootagsInput {
//     padding: 15px 10px;
//   }
// }
