@import url("https://fonts.googleapis.com/css?family=Montserrat");

body {
  font-family: "Montserrat", sans-serif;
  background-color: #eee;
  color: #111;
}

.score {
  font-family: monospace;
  text-align: center;
  font-size: 2rem;
  font-weight: bold;
  letter-spacing: 0.25rem;
  margin: 1rem;
  position: relative;
  transition: opacity 0.2s;
}
#play-again-btn {
  position: absolute;
  top: -0.5rem;
  left: 50%;
  margin-left: -50px;
  font-size: 1rem;
  font-weight: bold;
  color: #fff;
  background-color: #111;
  border: 5px double #fff;
  border-radius: 14px;
  padding: 8px 10px;
  outline: none;
  letter-spacing: 0.05em;
  cursor: pointer;
  display: none;
  opacity: 0;
  transition: opacity 0.5s, transform 0.5s, background-color 0.2s;
}
#play-again-btn:hover {
  background-color: #333;
}
#play-again-btn:active {
  background-color: #555;
}
#play-again-btn.play-again-btn-entrance {
  opacity: 1;
  transform: translateX(6rem);
}
.draggable-items {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  transition: opacity 0.5s;
  height: 100%;
}
.draggable {
  height: 6.5rem;
  width: 6.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 4rem;
  font-weight: bold;
  margin: 0.5rem 0;
  cursor: move;
  transition: opacity 0.2s;
  object-fit: cover;
}
.draggable:hover {
  opacity: 0.5;
}
.matching-pairs {
  transition: opacity 0.5s;
}
.matching-pair {
  width: 22rem;
  margin: auto;
  display: flex;
  justify-content: space-around;
}
.matching-pair span {
  display: flex;
  justify-content: center;
  align-items: center;
  /* text-align: center; */
  user-select: none;
  margin: 0.5rem auto;
}
.label {
  height: 6.8rem;
  width: 15rem;
  font-size: 2rem;
  text-transform: capitalize;
}
.droppable {
  height: 6.8rem;
  width: 7rem;
  font-size: 4rem;
  background-color: #fff;
  border: 3px dashed #111;
  transition: 0.2s;
}
.droppable-hover {
  background-color: #bee3f0;
  transform: scale(1.1);
}
.dropped {
  border-style: solid;
}
.dragged {
  user-select: none;
  opacity: 0.1;
  cursor: default;
}
.draggable.dragged:hover {
  opacity: 0.1;
}

@media (max-width: 600px) {
  html {
    font-size: 14px;
  }
  #play-again-btn {
    top: -0.4rem;
  }
  #play-again-btn.play-again-btn-entrance {
    transform: translateX(7rem);
  }
}
