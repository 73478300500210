.nodeContainer{
    height: fit-content;
    min-height:80px;
    width:175px; 
    border: 1px solid black;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 10px;
    padding-left:10px;
    background-color: whitesmoke;
}
.nodeHeading1{
    color: rgb(75, 64, 64);
}
.nodeHeading2{
    color: rgb(75, 64, 64);
    margin-top: 0px;
}
.nodeStartContainer{
    background-color:#E75480;
    border-radius: 10px;
    padding-left: 15px;
    padding-right:15px;
    height:20px;
    color:white;
    align-self: flex-start;
    font-weight: 600;
}