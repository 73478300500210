.reactFlowContainer{
    background-color: #FFF5EE;
    display: flex;
    flex:1;
    margin:40px;
    margin-top:100px;
}
.outerContainer{
    display: flex;
    flex:1;
    height: 100vh;
    width:100vw;
    margin:0;
    background-color:  #E30B5C;
}